













































































































import type ICreatingTickets from '@/models/ICreatingTickets'
import CirclePreloader from '@/components/BaseCirclePreloader.vue'
import BaseTextArea from '@/components/BaseComponents/BaseTextArea/BaseTextArea.vue'
import VSelect from '@/components/BaseComponents/VSelect/VSelect.vue'
import BaseInputVuelidate from '@/components/BaseInputVuelidate.vue'
import SupportAttachFiles from '@/components/support/SupportAttachFiles.vue'
import TicketsService from '@/services/api/TicketsService'
import Jsona from 'jsona'
import Vue from 'vue'
import { mapActions } from 'vuex'

const formatter = new Jsona()

export default Vue.extend({
  name: 'SupportNewTicket',
  components: {
    CirclePreloader,
    SupportAttachFiles,
    BaseTextArea,
    BaseInputVuelidate,
    VSelect
  },
  data: () => ({
    loaded: 0,
    total: 0,
    sending: false,
    categories: null,
    category: null,
    subject: '',
    message: '',
    files: [],
    errors: {
      subject: false,
      message: false
    }
  }),
  watch: {
    message (): void {
      if (this.message) {
        this.errors.message = false
      }
    },
    subject (): void {
      if (this.subject) {
        this.errors.subject = false
      }
    }
  },
  methods: {
    ...mapActions('notifications', ['addNotification']),
    validate (): void {
      if (!this.subject) {
        this.errors.subject = true
      }
      if (!this.message) {
        this.errors.message = true
      }
    },
    onsubmit (): void {
      this.validate()
      if (this.submitDisabled) {
        return
      }
      this.sending = true

      this.createNewTicket()
        .then(() => {
          this.$router.push('/support')
        })
        .finally(() => {
          this.sending = false
          this.loaded = 0
          this.total = 0
        })
    },
    onUploadProgressCreateNewTicket (progressEvent: ProgressEvent): void {
      this.loaded = progressEvent.loaded
      this.total = progressEvent.total
    },
    async createNewTicket (): Promise<void> {
      const formData = new FormData()
      formData.append('category_id', this.category.id)
      formData.append('subject', this.subject)
      formData.append('message', this.message)

      this.files.forEach((file: File, index: number) => {
        formData.append(`files[${index + 1}]`, file)
      })

      try {
        await TicketsService.create_ticket(
          formData as unknown as ICreatingTickets,
          { onUploadProgress: this.onUploadProgressCreateNewTicket }
        )
      } catch (error) {
        const { response } = error

        if (response?.data?.errors instanceof Array) {
          for (const error of response.data.errors) {
            this.addNotification({
              title: error.title,
              body: error.detail[0],
              type: 'danger'
            })
          }
        } else if (error.code === 'ERR_NETWORK') {
          this.addNotification({
            title: 'Error',
            body: 'Network error, please retry later',
            type: 'danger'
          })
        }

        throw error
      }
    },
    async loadCategories (): Promise<void> {
      const axiosResponse = await TicketsService.get_category()
      this.categories = formatter.deserialize(axiosResponse.data)
      this.category = this.categories[0]
    },
    removeFile (file): void {
      this.files = this.files.filter(_ => _ !== file)
    }
  },
  computed: {
    errorError (): boolean {
      return !!Object.values(this.errors)?.find(value => !!value)
    },
    errorFileSize (): boolean {
      return !!this.files.find(file => file.size > 0x3200000)
    },
    errorFileCount (): boolean {
      return this.files.length > 10
    },
    submitDisabled (): boolean {
      return !this.category ||
        this.errorError ||
        this.errorFileSize ||
        this.errorFileCount
    }
  },
  mounted () {
    this.loadCategories()
  }
})

