









































































import CirclePreloader from '@/components/BaseCirclePreloader.vue'
import NewUiAccordion from '@/components/NewUIComponents/NewUiAccordion.vue'
import Vue, { PropType } from 'vue'

function blobToBase64 (blob: Blob): Promise<string> {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result as string)
    reader.readAsDataURL(blob)
  })
}

type FileFromBackend = {
  ext: string,
  filename: string,
  store_filename: string,
  link: string
}

class SupportFileListImage {
  file?: File
  name: string
  image?: string
  link?: string
  promise: Promise<void> | null
  update: () => void

  constructor (...args: [FileFromBackend | File, () => void]) {
    this.update = args[1]
    if (args[0] instanceof File) {
      this.file = args[0]
      this.name = args[0].name
      this.promise = this.getImageFromFile()
    } else {
      this.image = args[0].link
      this.name = args[0].filename
      this.promise = null
    }
  }

  async getImageFromFile (): Promise<void> {
    try {
      this.image = await blobToBase64(this.file)
    } finally {
      this.promise = null
      this.update()
    }
  }
}

export default Vue.extend({
  name: 'SupportFileList',
  components: { CirclePreloader, NewUiAccordion },
  data: () => ({
    images: []
  }),
  props: {
    files: {
      type: Array as PropType<Array<File> | Array<FileFromBackend>>
    }
  },
  watch: {
    files () {
      this.updateImages()
    }
  },
  methods: {
    updateImages (): void {
      this.images = this.files
        .map(newFile =>
          this.images.find(oldImage => oldImage.file === newFile) ||
          new SupportFileListImage(newFile, this.forceUpdate))
    },
    forceUpdate (): void {
      this.$forceUpdate()
    },
    removeFile (file: File): void {
      this.$emit('remove-file', file)
    },
    toHumanSize (size: number): string {
      if (size >= 0x100000) {
        return (size / 0x100000).toFixed(1) + ' MB'
      }
      if (size >= 0x400) {
        return (size / 0x400).toFixed(1) + ' KB'
      }
      return size.toFixed(1) + ' B'
    },
    imageError (event: ErrorEvent): void {
      event.preventDefault()
      const target = event.target

      if (target instanceof HTMLImageElement) {
        target.style.display = 'none'
      }
    }
  },
  created (): void {
    if (this.files) {
      this.updateImages()
    }
  }
})

