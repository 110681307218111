import { AxiosRequestConfig, AxiosResponse } from 'axios'
import http from '@/http-common'
import ICreatingTickets from '@/models/ICreatingTickets'
import ICloseTicket from '@/models/ICloseTicket'
import urlGen from '@/utils/urlGen'

const baseUrl = String(process.env.VUE_APP_API_BASE_URL)

export default {
  get_category (): Promise<AxiosResponse> {
    return http.get('/categories')
  },
  get_tickets (params = {}): Promise<AxiosResponse> {
    const url = new URL(baseUrl + 'tickets')
    const urlParams = urlGen.addSearch(params)
    return http.get(url.toString() + '?' + urlParams.toString())
  },

  create_ticket (data: ICreatingTickets, config?: AxiosRequestConfig): Promise<AxiosResponse> {
    return http.post('/tickets', data, config)
  },

  add_ticket_message (id: number, data: ICreatingTickets, config?: AxiosRequestConfig): Promise<AxiosResponse> {
    return http.post(`/tickets/${id}/messages`, data, config)
  },

  close_ticket (id: ICloseTicket): Promise<AxiosResponse> {
    return http.patch(`/tickets/${id}`, { status: 'closed' })
  },

  show_ticket (id: string): Promise<AxiosResponse> {
    return http.get(`/tickets/${id}`)
  }
}
