













import Vue, { PropType } from 'vue'

type NewUiAccordionStatus = 'unknown' |
  'start-collapsing' | 'collapsing' | 'collapsed' |
  'start-extending' | 'extending' | 'extended'

type Data = {
  status: NewUiAccordionStatus,
  timeoutId: number,
  animationId: number
}

export default Vue.extend({
  name: 'NewUiAccordion',
  data (): Data {
    return {
      status: 'unknown',
      timeoutId: -1,
      animationId: -1
    }
  },
  watch: {
    collapsed (value) {
      (value ? this.startCollapsing : this.startExtending)()
    }
  },
  props: {
    collapsed: {
      type: Boolean,
      default: false
    },
    duration: {
      type: [Number, String],
      default: 250
    },
    easing: {
      type: String as PropType<CSSStyleDeclaration['transitionTimingFunction']>,
      default: 'ease-in-out'
    }
  },
  methods: {
    startCollapsing (): void {
      this.status = 'start-collapsing'
      this.clearTimeout()
      this.clearAnimation()
      this.$el.style.height = `${this.$el.clientHeight}px`
      this.animationId = requestAnimationFrame(this.collapsing)
    },
    collapsing (): void {
      this.animationId = -1
      this.status = 'collapsing'
      this.$el.style.height = '0'
      this.timeoutId = setTimeout(this.endCollapsing, this._duration)
    },
    endCollapsing (): void {
      this.timeoutId = -1
      this.$el.style.removeProperty('height')
      this.status = 'collapsed'
    },
    startExtending (): void {
      this.status = 'start-extending'
      this.clearTimeout()
      this.$el.style.height = this.clearAnimation() ? `${this.$el.clientHeight}px` : '0'
      this.animationId = requestAnimationFrame(this.extending)
    },
    extending (): void {
      this.animationId = -1
      this.status = 'extending'
      this.$el.style.height = `${this.$el.scrollHeight}px`
      this.timeoutId = setTimeout(this.endExtending, this._duration)
    },
    endExtending (): void {
      this.timeoutId = -1
      this.$el.style.removeProperty('height')
      this.status = 'extended'
    },
    clearTimeout (): boolean {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId)
        this.timeoutId = -1
        return true
      }
      return false
    },
    clearAnimation (): boolean {
      if (this.animationId) {
        cancelAnimationFrame(this.animationId)
        this.animationId = -1
        return true
      }
      return false
    }
  },
  computed: {
    _duration () {
      const value = parseInt(this.duration)

      return isFinite(value) && this.duration >= 0 ? value : 250
    }
  },
  created (): void {
    this.status = this.collapsed ? 'collapsed' : 'extended'
  },
  beforeDestroy (): void {
    this.clearTimeout()
    this.clearAnimation()
  }
})
