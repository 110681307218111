



















import Vue, { PropType } from 'vue'

type BadgeColors =
  'green' |
  'gray' |
  'blue' |
  'dark-yellow' |
  'dark-gray' |
  'red' |
  'dark-blue'

const availableBadgeColors: Array<BadgeColors> = [
  'green',
  'gray',
  'blue',
  'dark-yellow',
  'dark-gray',
  'red',
  'dark-blue'
]

export default Vue.extend({
  name: 'NewUiStatusBadge',
  props: {
    text: {
      type: String,
      default: 'Unknown'
    },
    color: {
      type: String as PropType<BadgeColors | string>
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    markerClass (): BadgeColors | undefined {
      return this.color && availableBadgeColors.includes(this.color)
        ? this.color
        : undefined
    },
    markerStyle (): Record<string, string> | undefined {
      return this.color && !availableBadgeColors.includes(this.color)
        ? { color: this.color }
        : undefined
    }
  }
})

