































import Vue from 'vue'

export default Vue.extend({
  name: 'BaseInputVuelidate',
  data () {
    return {
      val: '',
      errorMessage: '',
      errorMessages: []
    }
  },
  props: {
    v: {
      type: Object
    },
    title: {
      type: String
    },
    showTitle: {
      type: Boolean,
      default: true
    },
    showLength: {
      type: Boolean,
      default: true
    },
    name: {
      type: String
    },
    min: {
      type: Number
    },
    max: {
      type: Number
    },
    type: {
      type: String,
      default: 'text'
    },
    required: {
      type: Boolean,
      default: false
    },
    errorMessageProp: {
      type: String,
      default: ''
    },
    errorClass: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: 'Placeholder text...'
    },
    validationRule: {
      type: String,
      required: false
    },
    validationRegexp: {
      type: String,
      required: false
    },
    minWidth: {
      type: [Number, String],
      default: 300
    },
    maxWidth: {
      type: [Number, String],
      default: 'auto'
    },
    maxHeight: {
      type: [Number, String],
      default: 'auto'
    },
    default: {
      required: false,
      default: null
    },
    valueKey: {
      required: false,
      type: String
    },
    forbiddenRegexp: {
      required: false,
      type: String
    },
    inputClass: {
      required: false,
      type: String
    },
    value: {
      default: null
    },
    isCheckValue: {
      required: false,
      type: Boolean
    },
    disabled: {
      type: Boolean
    }
  },
  watch: {
    isCheckValue (check) {
      if (check) this.checkValue()
    },
    value (v) {
      this.val = v
    },
    val (v) {
      this.throwValue()
    }
  },
  mounted () {
    if (this.default) {
      this.val = this.default
    }
  },
  updated () {
    if (this.default) {
      //  this.val = this.default
    }
  },
  methods: {
    checkValue () {
      if (this.validationRegexp) {
        const regexQuery = this.validationRegexp
        const test = new RegExp(regexQuery, 'i')
        if (!test.test(this.val)) {
          this.errorMessage = 'This field must be valid'
        }
      }
    },
    throwValue () {
      // off all errors
      this.errorMessage = ''
      // throw value of input
      if (this.forbiddenRegexp) {
        this.val = this.val.replace(new RegExp(this.forbiddenRegexp, 'gi'), '')
      }
      this.checkValue()
      if (this.v) {
        this.v.$touch()
      }
      this.$emit('changeValue', { value: this.val, validity: !this.errorMessage.length, key: this.valueKey })
    }
  }
})
