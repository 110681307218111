



























import { PropType } from 'vue'
import Paginate from 'vuejs-paginate'
import { mapGetters } from 'vuex'

export default {
  name: 'NewUiPagination',
  components: {
    Paginate
  },
  props: {
    /**
     * Total count of pages.
     * @default: 1
     */
    pageCount: {
      type: Number,
      default: 1
    },
    /**
     * Range of pages which displayed.
     * @default: 3
     * (Note: It is recommended to use an odd number,
     * so that the same number of pages are displayed before and after the active page.
     * If using an even number, there will be one more page number before the active page than after the current page)
     */
    pageRange: {
      type: Number,
      default: 3
    },
    /**
     * The number of displayed pages for margins.
     * @default: 1
     */
    marginPage: {
      type: Number,
      default: 1
    },
    /**
     * Current page.
     * @default: undefined (current page will be processed automatically)
     */
    page: {
      type: Number
    },
    small: {
      type: Boolean,
      default: false
    },
    color: {
      type: String as PropType<'' | 'green' | 'dark-violet' | 'violet'>,
      default: ''
    }
  },
  computed: {
    ...mapGetters(['get_width']),
    mobile (): boolean {
      return this.get_width <= 768
    },
    containerClass (): string {
      const classes = ['pagination', 'pagination-type-1']

      if (this.small) {
        classes.push('small')
      }

      if (this.color) {
        classes.push(this.color)
      }

      return classes.join(' ')
    }
  },
  methods: {
    clickHandler (page: number): void {
      this.$emit('change-page', page)
    }
  }
}
