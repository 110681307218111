

























































































































import CirclePreloader from '@/components/BaseCirclePreloader.vue'
import BaseTextArea from '@/components/BaseComponents/BaseTextArea/BaseTextArea.vue'
import BasePagePreloader from '@/components/BasePagePreloader.vue'
import SupportAttachFiles from '@/components/support/SupportAttachFiles.vue'
import SupportFileList from '@/components/support/SupportFileList.vue'
import ICreatingTickets from '@/models/ICreatingTickets'
import TicketsService from '@/services/api/TicketsService'
import Jsona from 'jsona'
import moment from 'moment'
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'

const formatter = new Jsona()

export default Vue.extend({
  name: 'SupportTicket',
  components: { CirclePreloader, SupportAttachFiles, BaseTextArea, SupportFileList, BasePagePreloader },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data: () => ({
    files: [],
    ticket: null,
    loaded: 0,
    total: 0,
    sending: false,
    message: '',
    messageRequired: false
  }),
  watch: {
    message (): void {
      if (this.message) {
        this.messageRequired = false
      }
    }
  },
  methods: {
    ...mapActions('notifications', ['addNotification']),
    validate (): void {
      if (!this.message) {
        this.messageRequired = true
      }
    },
    onsubmit (): void {
      this.validate()
      if (this.submitDisabled) {
        return
      }
      this.sending = true

      this.sendReply()
        .then((): void => {
          this.loadTicket()
        })
        .finally((): void => {
          this.message = ''
          this.files = []
          this.sending = false
          this.loaded = 0
          this.total = 0
        })
    },
    onUploadProgressSendReply (progressEvent: ProgressEvent): void {
      this.loaded = progressEvent.loaded
      this.total = progressEvent.total
    },
    async sendReply (): Promise<void> {
      const formData = new FormData()
      formData.append('message', this.message)

      this.files.forEach((file: File, index: number) => {
        formData.append(`files[${index + 1}]`, file)
      })

      try {
        await TicketsService.add_ticket_message(
          this.id,
          formData as unknown as ICreatingTickets,
          { onUploadProgress: this.onUploadProgressSendReply }
        )
      } catch (error) {
        const { response } = error

        if (response?.data?.errors instanceof Array) {
          for (const error of response.data.errors) {
            this.addNotification({
              title: error.title,
              body: error.detail[0],
              type: 'danger'
            })
          }
        }

        throw error
      }
    },
    async loadTicket (): Promise<void> {
      const axiosResponse = await TicketsService.show_ticket(this.id)
      this.ticket = formatter.deserialize(axiosResponse.data)
    },
    async closeTicket (): Promise<void> {
      await TicketsService.close_ticket(this.id)
      this.$router.push('/support').then()
    },
    formatDate (date: string): string {
      return moment(date).local().format('MMM D, YYYY, HH:mm')
    },
    removeFile (file): void {
      this.files = this.files.filter(_ => _ !== file)
    },
    updateFileList (event: Array<File>): void {
      this.files = [...this.files, ...event]
    }
  },
  computed: {
    ...mapGetters('profile', ['currentUser']),
    errorFileSize (): boolean {
      return !!this.files.find(file => file.size > 0x3200000)
    },
    errorFileCount (): boolean {
      return this.files.length > 10
    },
    submitDisabled (): boolean {
      return !this.ticket ||
        this.messageRequired ||
        this.errorFileSize ||
        this.errorFileCount
    }
  },
  mounted (): void {
    this.loadTicket()
  }
})
