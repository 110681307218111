
























import Vue from 'vue'

export default Vue.extend({
  name: 'BaseTextArea',
  data () {
    return {
      val: '',
      errorMessage: '',
      errorMessages: []
    }
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    name: {
      type: String
    },
    min: {
      type: Number
    },
    max: {
      type: Number
    },
    initialData: {
      type: Array
    },
    type: {
      type: String,
      default: 'text'
    },
    required: {
      type: Boolean,
      default: false
    },
    requiredMessage: {
      type: String,
      default: 'This field must not be empty'
    },
    placeholder: {
      type: String,
      default: 'Placeholder text...'
    },
    validationRule: {
      type: String,
      required: false
    },
    default: {
      required: false,
      default: null
    },
    errorMessageProp: {
      type: String,
      default: ''
    },
    value: {
      type: String
    }
  },
  watch: {
    value (v) {
      this.val = v
    }
  },
  methods: {
    checkValue () {
      if (this.validationRule === 'email') {
        if (!this.validEmail(this.val)) {
          this.errorMessage = 'Email must be valid'
        }
      }
      if (this.required && this.val.length === 0) {
        this.errorMessage = this.requiredMessage
      }
    },
    throwValue (evt) {
      // off all errors
      this.errorMessage = ''

      this.val = evt.target.value
      // throw value of input
      this.$emit('changeValue', { value: this.val, validity: !!this.errorMessage.length })
    },
    validEmail (val: string) {
      const regexQuery = '^\\w+([\\+.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$'
      const email = new RegExp(regexQuery, 'i')
      return email.test(val)
    }
  },
  mounted () {
    if (this.default) {
      this.val = this.default
    }
  }
})
