































































































































import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import VSelect from '@/components/BaseComponents/VSelect/VSelect.vue'
import BasePagePreloader from '@/components/BasePagePreloader.vue'
import NewUiPagination from '@/components/NewUIComponents/NewUiPagination.vue'
import NewUiStatusBadge from '@/components/NewUIComponents/NewUiStatusBadge.vue'
import TicketsService from '@/services/api/TicketsService'
import Jsona from 'jsona'
import Vue from 'vue'
import moment from 'moment'

const formatter = new Jsona()

const statusList = [
  {
    text: 'All',
    value: null
  },
  {
    text: 'Awaiting your reply',
    value: {
      is_closed: false,
      is_support: true
    }
  },
  {
    text: 'Open',
    value: {
      is_closed: false,
      is_support: false
    }
  },
  {
    text: 'Solved',
    value: {
      is_closed: true
    }
  }
]

export default Vue.extend({
  name: 'Support',
  components: {
    NewUiStatusBadge,
    BasePagePreloader,
    NewUiPagination,
    DatePicker,
    VSelect
  },
  data: () => ({
    statusList,
    statusValue: statusList[0],
    dateRange: [],
    pagination: {
      total: 10,
      count: 10,
      per_page: 10,
      current_page: 1,
      total_pages: 1
    },
    tickets: null
  }),
  computed: {
    per_page: {
      get (): number {
        return this.pagination.per_page
      },
      set (value: number): void {
        this.pagination.per_page = value
        this.loadTickets()
      }
    },
    current_page: {
      get (): number {
        return this.pagination.current_page
      },
      set (value: number): void {
        this.pagination.current_page = value
        this.loadTickets()
      }
    },
    computedCreatedAtParams (): {
      'created_at[from]': string
      'created_at[to]': string
    } | undefined {
      return this.dateRange && this.dateRange[0] && this.dateRange[1]
        ? {
          'created_at[from]': this.dateRange[0],
          'created_at[to]': this.dateRange[1]
        } : undefined
    }
  },
  watch: {
    computedCreatedAtParams (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.loadTickets()
      }
    }
  },
  methods: {
    ticketStatus (ticket) {
      return ticket.is_closed ? 'Solved' : ticket.is_support ? 'Awaiting your reply' : 'Open'
    },
    setStatusValue (value: unknown): void {
      if (this.statusValue !== value) {
        this.statusValue = value
        this.loadTickets()
      }
    },
    async loadTickets (): Promise<void> {
      const axiosResponse = await TicketsService.get_tickets({
        per_page: this.pagination.per_page,
        page: this.pagination.current_page,
        ...this.statusValue.value,
        ...this.computedCreatedAtParams
      })
      this.tickets = formatter.deserialize(axiosResponse.data)
      this.pagination = axiosResponse.data.meta.pagination
    },
    date_customizer (date) {
      return moment(date).local().format('DD/MM/yy HH:mm')
    },
    plainText (text: string): string {
      return text.replace(/<[^>]*>/g, '')
    }
  },
  mounted () {
    this.loadTickets()
  }
})
