





































import SupportFileList from '@/components/support/SupportFileList.vue'
import Vue, { PropType } from 'vue'

export default Vue.extend({
  name: 'SupportAttachFiles',
  components: { SupportFileList },
  props: {
    files: {
      type: Array as PropType<Array<File>>
    }
  },
  methods: {
    onchange (): void {
      this.$emit('update-file-list', Array.from(this.$refs.inputFile.files))
    }
  },
  mounted () {
    this.$emit('update-file-list', Array.from(this.$refs.inputFile.files))
  }
})
